<template>
  <div>
    <v-skeleton-loader
      v-if="isLoadingCard"
      v-for="i,index in 3"
      :key="index"
      type="image"
      height="150"
      class="mb-4">
    </v-skeleton-loader>
    <v-card
      v-for="announcement,index in announcements"
      :key="index"
      class="mb-4"
      height="150"
    >
      <v-card-title class="text-capitalize">
        {{ announcement.title }}
      </v-card-title>
      <v-card-subtitle>{{ getFormatDate(announcement.created_at) }}</v-card-subtitle>
      <v-card-text>
        <p class="" style="">{{ announcement.content }}</p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Announcement',
  components: {},
  data() {
    return {
      details: [],
      kadis_uuid: null,
      isLoadingCard: false,
      announcement: {
        title: '',
        start_date: '',
        end_date: '',
        content: '',
        time_set: '',
      },
      service: 'announcementheadmaster',
      search: '',
      announcements: [],
      filterQuery: {
        search: '',
      },
    }
  },
  watch: {
    search: {
      handler() {
        if (this.search.length > 3 || this.search.length === 0) {
          if (this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.listAnnouncement()
          }, 500)
        }
      },
    },
  },
  beforeMount() {
    this.getHeaddepartmentUuid()
  },
  mounted() {
    this.listAnnouncement()
  },
  methods: {
    async listAnnouncement(params = {}) {
      this.isLoadingCard = true
      await this.$services.ApiServices.list(this.service, {
        ...params,
        school_uuid: this.school_uuid,
      }).then(({ data }) => {
        this.announcements = data.data
      })
      this.isLoadingCard = false
    },
    getHeaddepartmentUuid() {
      this.details = JSON.parse(localStorage.getItem('user')).user.details
      this.details.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: 'you dont have access to school forum',
            color: 'error',
          })

          return
        }
        this.school_uuid = el.school.uuid
      })

      return true
    },
    getFormatDate(date) {
      const createdDate = new Date(date)
      const day = `${createdDate.getDate()}`
      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
      const month = `${monthNames[createdDate.getMonth()]}`
      const year = createdDate.getFullYear()

      return `${day.slice(-2)} ${month} ${year}`
    },
  },
}
</script>

<style>
.v-card__text p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
